import OnloadHandler from '../packs/onload_handler';

OnloadHandler(function () {
 if (window.location.pathname === '/cloud') {
        retrieveData()
    }
    if (window.location.pathname === '/tags') {
       tagManager ()
    }
    var x;
    var first_timeout_set = false;

    function retrieveData() {
    clearTimeout(x)
    $.get("/tag_cloud", function (data) {
        var second_insert = data.filter(function (elem) {
            return elem.second_insert
        });
        second_insert = second_insert.length;
        $('#cloud').jQCloud(data, {
            delay: 70,
            shape: 'rectangular',
            classPattern: null,
            colors: ["#00468E", "#006AA3", "#00A1B2", "#CBBCA5", "#AC956B", "#C4AA7A", "#00a2dd", "#F5D498", "#00C5DB"],
            fontSize: {
                from: 0.1,
                to: 0.02
            }
        });

        if (second_insert != 0) {
            if (second_insert == 1 && !first_timeout_set) {
                setTimeout(function () {
                    retrieveData();
                    first_timeout_set = true
                }, 180000);
            } else {
                $('#cloud').jQCloud('update', data);
                setTimeout(function () {
                    retrieveData();
                }, 15000);
            }
        } else {
            x = setTimeout(function () {
                retrieveData()
            }, 5000);
        }
    });
}



var timerTagManager;
function tagManager(){
    $.get("/tag_cloud", function (data) {
        var tagTrCount = $(".count-tr").length
        if(tagTrCount != data.length){
            alert("Sono state inserite nuove parole. Ricaricare la pagina!")
            timerTagManager= ""
        }else{
            timerTagManager = setTimeout(function () {
                tagManager();
            }, 1500);
        }
    });
}

});